$(function () {
    if ($(window).width() > 768){
        var pagetop = $('.p-footer-pagetop');
        pagetop.hide();
        $(window).on('scroll', function (){
            if ($(this).scrollTop() > 500)
            {
                pagetop.fadeIn();
            } else
            {
                pagetop.fadeOut();
            }
        });

        // $(window).on('load scroll', function (){
        //     var height = $(document).height();
        //     var position = window.innerHeight + $(window).scrollTop();
        //     var footer = $('.p-footer').height();

        //     if (height - position <= footer)
        //     {
        //         pagetop.addClass('absolute');
        //     } else
        //     {
        //         pagetop.removeClass('absolute');
        //     }
        // })
    }

});